<template>
    <div>
        <div class="container cloud">
            <div class="wrapper">
                <div class="grid" v-if="loaded && loadedResults">
                    <div class="col-12">
                        <h1 class="fs-h2">{{ results }} {{ Number(results) === 1 ? 'søkeresultat' : 'søkeresultater' }} ble funnet på ditt søk etter &laquo;{{ query }}&raquo;</h1>
                    </div>
                    <div class="col-12" v-if="loadingPage">
                        <div class="centered-container">
                            <span class="gn gn-icons spinner"></span>
                        </div>
                    </div>
                    <div v-else class="col-4 col-md-6 col-sm-12" v-for="post in content" :key="post._key">
                        <div class="badge">
                            <div class="top">
                                <router-link :to="{ name: post._type === 'post' ? 'post' : 'page', params: { slug: getSlug(post) }}" class="image" :style="{ backgroundImage: `url('${getImageUrl(post.mainImage)}')` }" />
                            </div>
                            <div class="bottom">
                                <div class="flex flex-group flex-wrap" v-if="post.categories && post.categories.length > 0">
                                    <router-link v-for="cat in post.categories" :to="{ name: 'category', params: { slug: getSlug(cat) }}" :key="cat._key" href="" class="pill">{{ cat.title }}</router-link>
                                </div>
                                <h2><router-link :to="{ name: post._type === 'post' ? 'post' : 'page', params: { slug: getSlug(post) }}">{{ post.title }}</router-link></h2>
                                <p v-if="post.intro"><router-link :to="{ name: post._type === 'post' ? 'post' : 'page', params: { slug: getSlug(post) }}">{{ getIntro(post) }}</router-link></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="pages.length > 1">
                        <div class="page-numbers">
                            <template v-if="!pages.includes(1)">
                                <a @click="() => goToPage(1)">1</a>
                                <template v-if="!pages.includes(2)">
                                    ...
                                </template>
                            </template>
                            <template v-for="(page, index) in pages">
                                <a v-if="getCurrentPageNumber() != page" @click="() => goToPage(page)" :key="index">{{ page }}</a>
                                <span class="active" :key="index" v-else>{{ page }}</span>
                            </template>
                            <template v-if="!pages.includes(getTotalPageNumbers())">
                                <template v-if="!pages.includes(getTotalPageNumbers() - 1)">
                                    ...
                                </template>
                                <a @click="() => goToPage(getTotalPageNumbers())">{{ getTotalPageNumbers() }}</a>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import sanity from '../../sanity';
    import date from '../../helpers/date';
    import imageUrlBuilder from '@sanity/image-url';
    import text from '../../helpers/text';
    import substring from '../../helpers/substring';
    import slug from '../../helpers/slug';

    const imageBuilder = imageUrlBuilder(sanity);

    export default {
        data() {
            return {
                query: '',
                loading: false,
                loadingResults: false,
                loaded: false,
                loadedResults: false,
                loadingPage: false,
                category: null,
                content: [],
                pages: [],
                results: 0,
                hasAcceptedDisclaimer: false,
                professionals: false,
                perPage: 9,
            }
        },

        metaInfo () {
            return {
                title: 'Søk : ',
            }
        },

        created() {
            this.query = decodeURIComponent(this.$route.query.q);
            this.updateSearchResults();
            this.getPageNumbers();
        },

        watch: {
            '$route.query.q': function() {
                this.query = decodeURIComponent(this.$route.query.q);
                this.updateSearchResults();
            },
            '$route.query.page': function() {
                this.updateSearchResults();
            },
        },

        methods: {
            async updateSearchResults () {
                this.loadingPage = true;
                await this.fetchContent(this.query, this.$route.query.page);
                await this.fetchResults(this.query);
                this.pages = this.getPageNumbers();
                this.loadingPage = false;

                const totalPages = this.getTotalPageNumbers();

                if (this.getCurrentPageNumber() < 1) {
                    this.$router.push({ name: 'search', query: { q: encodeURIComponent(this.query), page: 1 } }).catch(() => null);
                } else if(this.getCurrentPageNumber() > totalPages) {
                    this.$router.push({ name: 'search', query: { q: encodeURIComponent(this.query), page: totalPages } }).catch(() => null);
                }
            },
            fetchContent(query, page = 1) {
                return new Promise((resolve) => {
                    this.loading = true;
                    sanity.fetch(`
                        *[(_type == "post" || _type == "page") && (title match $query || intro[].children[].text match $query || body[].children[].text match $query) && !(_id in path('drafts.**')) && (publishedAt == null || publishedAt < $date)]{
                            ...,
                            "title": @.title,
                            "slug": @.slug,
                            "top": [@.top == true][0],
                            categories[]{
                                ...,
                                "title": @->title,
                                "slug": @->slug
                            },
                        } | order(title)[$offset...$limit]
                    `, {
                        query: `*${query || ''}*`,
                        date: date.currentFullUTCDate(),
                        offset: this.perPage * (page - 1),
                        limit: this.perPage * page,
                    }).then((response) => {
                        this.content = response;
                    }, (error) => {
                        console.warn(error)
                    }).then(() => {
                        this.loading = false
                        this.loaded = true
                        resolve();
                    });
                });
            },
            fetchResults(query) {
                return new Promise((resolve) => {
                    this.loadingResults = true;
                    sanity.fetch(`
                        count(*[(_type == "post" || _type == "page") && (title match $query || intro[].children[].text match $query || body[].children[].text match $query) && !(_id in path('drafts.**')) && (publishedAt == null || publishedAt < $date)])
                    `, {
                        query: `*${query || ''}*`,
                        date: date.currentFullUTCDate(),
                    }).then((response) => {
                        this.results = response;
                    }, (error) => {
                        console.warn(error);
                    }).then(() => {
                        this.loadingResults = false;
                        this.loadedResults = true;
                        resolve();
                    });
                });
            },

            getIntro(post) {
                return substring(text(post.intro), 92)
            },

            getImageUrl(source) {
                return imageBuilder.image(source).format('webp').height(1080).url()
            },
            
            getSlug(element) {
                return slug(element)
            },

            getCurrentPageNumber () {
                return this.$route.query.page || 1;
            },

            getTotalPageNumbers () {
                if(this.results === 0) {
                    return 1;
                }


                return Math.ceil(this.results / this.perPage);
            },

            getPageNumbers () {
                const range = 5;
                const totalPages = this.getTotalPageNumbers();
                let currentPage = this.$route.query.page || 1;

                if(currentPage < 1) {
                    currentPage = 1;
                } else if(currentPage > totalPages) {
                    currentPage = totalPages;
                }

                let startRange = 1;
                let endRange = range;
                if(currentPage >= range - 1) {
                    startRange = currentPage - Math.floor(range / 2);
                    endRange = Number(currentPage) + Math.floor(range / 2);
                }

                if(endRange > totalPages) {
                    endRange = totalPages;
                    startRange = totalPages - range;
                }

                if(startRange <= 0) {
                    startRange = 1;
                }

                return [...Array(endRange - startRange + 1).keys()].map((i) => i + startRange);
            },

            goToPage(page) {
                this.$router.push({ name: 'search', query: { q: encodeURIComponent(this.query), page } }).catch(() => null);
            },
        }
    }
</script>